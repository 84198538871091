<template>
  <div>
    <!--begin::ListOperations -->
    <!-- DEV -->
    <b-card class="card-custom gutter-b mt-10" body-class="card-scroll h-250px p-3" v-if="showDataDebug">
      <pre>filteredOperationsLength: {{ filteredOperationsLength }}</pre>
      <pre>currentPage: {{ currentPage }}</pre>
      <pre>perPage: {{ perPage }}</pre>
      <pre>Filter: {{ filter }}</pre>
      <pre>this.$route.params: {{ this.$route.params }}</pre>
      <pre>selectVal: {{ selectVal }}</pre>
      <pre>Stored datas: {{ StoredDatas.datas.filteredOperations }}</pre>
    </b-card>
    <div v-if="filteredOperationsLength > 0" class="card card-custom --card-stretch gutter-b">
      <!--begin::Body-->
      <div class="card-body p-0">
        <!-- Filters -->
        <b-button
          v-b-toggle.list-filters
          size="sm"
          block
          variant="opalean-gray-light"
          class="p-5 rounded-sm text-left font-weight-bold m-0 --font-size-xs"
          :class="[this.showSearch === true || this.showCreateButton === true ? 'rounded-top-0' : '']"
          ><i class="flaticon2-dashboard"></i> <translate>Filters</translate>
          <span v-if="!haveAnyFilters()" class="float-right"><i class="la la-filter"></i></span>
        </b-button>
        <b-collapse id="list-filters" class="mt-0">
          <b-card
            class="border-top-0 rounded-top-0 rounded-sm"
            body-class="px-2 py-5 bg-opalean-whiter"
            :class="[this.showSearch === true || this.showCreateButton === true ? 'rounded-top-0' : '']"
          >
            <p class="card-text font-size-lg font-weight-bold d-none" v-translate>Filters</p>

            <b-container fluid class="px-0">
              <!-- User Interface controls -->
              <b-row>
                <b-col lg="6" class="">
                  <b-form-group
                    class="mb-0"
                    :label="$gettext('Filtering operations')"
                    label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                    :description="$gettext('Examples : Auchan, Grimonprez, Grim, 0003, AF, Opalean, Dusseldofer, 11/12...')"
                    label-for="filter-input"
                  >
                    <b-input-group size="lg" class="pt-3">
                      <b-form-input id="filter-input" v-model="filter" type="search" :placeholder="$gettext('Type to search')"></b-form-input>

                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''" v-translate>Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col lg="12" v-if="!haveAnyFilters()">
                  <b-button
                    block
                    variant="outline-opalean-gray-medium"
                    size="lg"
                    class="btn-hover-danger font-weight-bold"
                    v-if="!haveAnyFilters()"
                    :disabled="haveAnyFilters()"
                    @click="clearAllFilters($event)"
                    ><i class="flaticon2-refresh-button icon-md"></i> <translate>Clear all</translate></b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </b-collapse>

        <!--begin::Table-->
        <b-table
          id="list-table"
          class="table table-vertical-center mb-0"
          :items="filteredItems"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :busy="isBusy"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          @context-changed="onTableChanged"
          striped
          hover
          thead-class="font-uppercase d-none"
          :tbody-tr-class="rowClass"
          :foot-clone="showPagination"
          selectable
          selected-variant="opalean-gray-light"
          select-mode="multi"
          ref="selectableTable"
        >
          <!-- Busy state -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="d-block" v-translate>Loading</strong>
            </div>
          </template>

          <!-- Operation slot -->
          <template #cell(operationRow)="data">
            <div class="status isMobile h-100 w-100 position-absolute top-0 left-0"></div>
            <span v-if="data.item.OperationCMR" class="d-block --text-opalean-primary font-size-m font-weight-bolder mt-2 max-w-80px"
              ><i class="far fa-sticky-note icon-nm text-opalean-primary d-none"></i> {{ data.item.OperationCMR | safePrint }}</span
            >
            <span class="label label-sm label-outline-opalean-gray-medium label-inline font-weight-bolder --rounded-right-0 border-width-1 px-1">{{
              data.item.OperationDate | getDateFormat("LocaleDate")
            }}</span>
            <span class="label label-sm label-opalean-gray-medium label-inline font-weight-bold --rounded-left-0 border-width-1 max-w-80px d-none">{{
              data.item.OperationID | safePrint
            }}</span>
            <div class="mt-0 mb-1">
              <span
                class="label label-sm label-inline label-square font-weight-bold text-opalean-gray-medium px-2 mr-1 label-outline-secondary max-w-80px"
                v-for="(key, index) in data.item.Manifest"
                :key="index"
                :title="key.Type"
                v-b-popover.hover.html="`${key.Value}`"
                >{{ key.Value | safePrint }}</span
              >
            </div>
            <!-- Messages & Docs -->
            <span v-if="data.item.HasNewMessages" class="--d-block text-opalean-primary font-weight-bolder mt-2 mr-2"
              ><i class="far fa-comment-alt icon-nm text-opalean-gray-medium"></i
            ></span>
            <span v-if="data.item.HasMyDoc" class="--d-block text-opalean-primary font-weight-bolder mt-2 mr-2 ml-n1"
              ><i class="la la-paperclip icon-md text-opalean-primary"></i
            ></span>
            <span v-if="data.item.HasOtherDoc" class="--d-block text-opalean-primary font-weight-bolder mt-2 mr-2 ml-n1"
              ><i class="la la-paperclip icon-md text-warning"></i
            ></span>
            <!-- Status -->
            <b-badge
              pill
              v-if="
                !(
                  !getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'thirdparty', 'Attributes').includes(
                    'isClient'
                  ) && getStatusType(data.item.OperationStatus, 'id') === 'R'
                )
              "
              :variant="getStatusType(data.item.OperationStatus, 'class')"
              class="font-weight-bolder font-size-xs text-white"
              >{{ $gettext(getStatusType(data.item.OperationStatus, "name")) }}</b-badge
            >
            <!-- Do not print R badge for isNotClient counterpart -->
          </template>

          <!-- Flow slot -->
          <!-- *:svgIconSrc="getFlowType(data.item.OperationType, 'icon')"
              :inlineIconSrc="
                getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'thirdparty', 'isClient', null)
                  ? 'flaticon2-correct'
                  : null
              "*/ -->
          <template #cell(flowRow)="data">
            <DetailsPanel
              v-if="data.item.OperationType !== null && typeof data.item.OperationType === 'string'"
              :title="getFlowType(data.item.OperationType, 'name')"
              :titleDescription="getFlowType(data.item.OperationType, 'description')"
              :subtitle="getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'thirdparty', 'Name')"
              :subtitleDescription="
                getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'thirdparty', 'ZipCode,City,CountryISO,District')
              "
              :thirdtitle="getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'thirdparty', 'City')"
              :inverted="false"
              :mobile="true"
            >
            </DetailsPanel>

            <!-- Pallets-->
            <b-table-lite
              id="thirdparty-table"
              class="table-opalean-gray-light table-bordered table-vertical-center mt-1 mb-0"
              small
              thead-class="d-none"
              :items="getOperationPOVDetails(data.item.OperationType, data.item.MyRole, data.item.POVs, 'all', 'Pallets')[0]"
              :fields="palletFields"
              :tbody-tr-class="palletRowClass"
            >
              <!-- Icon slot -->
              <template #cell(iconRow)="d">
                <b-skeleton-wrapper v-if="d.item.PalletID != undefined" :loading="Pallets.length == 0">
                  <template #loading>
                    <b-skeleton animation="fade" width="80%"></b-skeleton>
                  </template>
                  <span
                    v-if="typeof getPalletType(getPalletValue(d.item.PalletID, 'PalletType'), 'class') !== 'undefined'"
                    class="svg-icon svg-icon-sm"
                    :class="getPalletType(getPalletValue(d.item.PalletID, 'PalletType'), 'class')"
                  >
                    <inline-svg :src="getPalletType(getPalletValue(d.item.PalletID, 'PalletType'), 'icon')"></inline-svg
                  ></span>
                </b-skeleton-wrapper>
              </template>

              <!-- Pallet slot -->
              <template #cell(palletRow)="d">
                <span class="font-weight-normal font-size-xs">{{ getPalletValue(d.item.PalletID, "PalletName") }}</span>
              </template>

              <!-- Accounts slots -->
              <!--  https://forum.vuejs.org/t/how-to-pass-cell-templates-to-a-component-with-b-table/106283/2 / #cell(toCellName(slot,index))="data" -->
              <template #cell(accountRow1)="d">
                <span
                  class="font-size-sm"
                  v-if="data.item.OperationType !== 'T'"
                  v-b-tooltip.hover.html="getAccountType(data.item.OperationType === 'D' ? 1 : 0, d.item.FP.toLocaleString(), 'name')"
                  v-html="getAccountType(data.item.OperationType === 'D' ? 1 : 0, d.item.FP.toLocaleString(), 'shortName')"
                >
                </span>
                <span
                  class="font-size-hsm6"
                  v-else
                  v-b-tooltip.hover.html="getAccountType(3, d.item.FP.toLocaleString(), 'name')"
                  v-html="getAccountType(3, d.item.FP.toLocaleString(), 'shortName')"
                >
                </span>
              </template>
              <template #cell(accountRow2)="d">
                <span
                  class="font-size-sm"
                  v-if="data.item.OperationType !== 'T' && !['P', 'L'].includes(getPalletValue(d.item.PalletID, 'PalletType'))"
                  v-b-tooltip.hover.html="getAccountType(2, d.item.FR.toLocaleString(), 'name')"
                  v-html="getAccountType(2, d.item.FR.toLocaleString(), 'shortName')"
                >
                </span>
              </template>
              <template #cell(accountRow3)="d">
                <span
                  class="font-size-h6"
                  v-if="!['P', 'L'].includes(getPalletValue(d.item.PalletID, 'PalletType'))"
                  :class="
                    getImpactAccount(data.item.OperationType, data.item.MyRole, data.item.HisRole, d.item.FP, d.item.FR) < 0 ? 'text-info' : 'text-opalean-info'
                  "
                  v-b-tooltip.hover.html="
                    getAccountType(
                      4,
                      (getImpactAccount(data.item.OperationType, data.item.MyRole, data.item.HisRole, d.item.FP, d.item.FR) <= 0 ? '' : '+') +
                        getImpactAccount(data.item.OperationType, data.item.MyRole, data.item.HisRole, d.item.FP, d.item.FR).toLocaleString(),
                      'name'
                    )
                  "
                  v-html="
                    getAccountType(
                      4,
                      (getImpactAccount(data.item.OperationType, data.item.MyRole, data.item.HisRole, d.item.FP, d.item.FR) <= 0 ? '' : '+') +
                        getImpactAccount(data.item.OperationType, data.item.MyRole, data.item.HisRole, d.item.FP, d.item.FR).toLocaleString(),
                      'shortName'
                    )
                  "
                >
                </span>
              </template>
              <!-- <template #cell(transfertRow1)="d">
                <span
                  class="font-size-h6"
                  v-if="data.item.OperationType === 'T'"
                  v-b-tooltip.hover.html="getAccountType(3, d.item.FP, 'name')"
                  v-html="getAccountType(3, d.item.FP, 'shortName')"
                >
                </span>
              </template>-->
            </b-table-lite>
          </template>

          <!-- Scoped slot for select state illustrative purposes -->
          <template #cell(actionRow)="data">
            <div class="actions isMobile h-100 w-60px position-absolute top-0 right-0">
              <b-button-group size="lg" class="d-flex h-100 p-0">
                <!-- Edit : mobile special  -->
                <b-button
                  squared
                  v-if="
                    getStatusType(data.item.OperationStatus, 'actions').includes('validate') ||
                    getStatusType(data.item.OperationStatus, 'actions').includes('reject')
                  "
                  :to="{
                    name: 'route.operations.edit',
                    params: {
                      OperationUID: data.item.OperationUID,
                      Validate: false,
                    },
                  }"
                  variant="info"
                  class="px-1 py-1 font-weight-bold h-100 d-flex flex-center"
                  v-b-tooltip.hover
                  :title="$gettext('Edit operation')"
                  ><i class="la la-edit icon-xl pr-0 px-3"></i
                ></b-button>
                <!-- Validate -->
                <!-- <b-button
                  squared
                  v-if="getStatusType(data.item.OperationStatus, 'actions').includes('validate')"
                  @click="validateOperation(data.item.OperationUID)"
                  variant="success"
                  class="pl-0 pr-1 py-1 font-weight-bold h-100 d-flex flex-center"
                  v-b-tooltip.hover
                  :title="$gettext('Validate all statements')"
                  ><i class="la la-check-circle icon-xl pr-0 px-3"></i
                ></b-button> -->
                <!-- Reject -->
                <!-- <b-button
                  squared
                  v-if="getStatusType(data.item.OperationStatus, 'actions').includes('reject')"
                  :to="{
                    name: 'route.operations.edit',
                    params: {
                      OperationUID: data.item.OperationUID,
                      Validate: false,
                    },
                  }"
                  variant="danger"
                  class="pl-0 pr-1 py-1 font-weight-bold h-100 d-flex flex-center"
                  v-b-tooltip.hover
                  :title="$gettext('Reject all or some statement(s)')"
                  ><i class="la la-exclamation-circle icon-xl pr-0 px-3"></i
                ></b-button> -->
                <!-- Create -->
                <!-- <b-button
                  squared
                  v-if="getStatusType(data.item.OperationStatus, 'actions').includes('create')"
                  :to="{
                    name: 'route.operations.edit',
                    params: {
                      OperationUID: 0,
                    },
                  }"
                  variant="hover-light-bg-success"
                  class="px-1 py-1 font-weight-bold h-100 d-flex flex-center"
                  v-b-tooltip.hover
                  :title="$gettext('Create operation')"
                  ><i class="la la-plus icon-xl pr-0 px-3"></i
                ></b-button> -->
                <!-- Edit -->
                <b-button
                  squared
                  v-if="getStatusType(data.item.OperationStatus, 'actions').includes('edit')"
                  :to="{
                    name: 'route.operations.edit',
                    params: {
                      OperationUID: data.item.OperationUID,
                    },
                  }"
                  :variant="data.item.OperationStatus === 'L' ? 'danger' : 'opalean-gray-light'"
                  class="px-1 py-1 font-weight-bold h-100 d-flex flex-center"
                  v-b-tooltip.hover
                  :title="$gettext('Edit operation')"
                  ><i class="la la-edit icon-xl pr-0 px-3"></i
                ></b-button>
                <!-- Add document -->
                <!-- <b-button
                  squared
                  v-if="getStatusType(data.item.OperationStatus, 'actions').includes('adddocument')"
                  :to="{
                    name: 'route.operations.edit',
                    params: {
                      OperationUID: data.item.OperationUID,
                      AddDocument: true,
                    },
                  }"
                  variant="opalean-info"
                  class="pl-1 pr-0 py-1 font-weight-bold h-100 d-flex flex-center"
                  v-b-tooltip.hover
                  :title="$gettext('Add document')"
                  ><i class="la la-paperclip icon-xl pr-0 px-3"></i
                ></b-button> -->
                <!-- Delete -->
                <!-- <b-button
                  squared
                  v-if="getStatusType(data.item.OperationStatus, 'actions').includes('delete')"
                  variant="danger"
                  class="pl-1 pr-0 py-1 font-weight-bold h-100 d-flex flex-center"
                  v-b-tooltip.hover
                  :title="$gettext('Delete operation')"
                  ><i class="la la-trash icon-xl pr-0 px-3"></i
                ></b-button> -->
                <!-- Misc actions -->
                <!-- 
                <b-dropdown
                  squared
                  v-if="getStatusType(data.item.OperationStatus, 'actions').includes('misc')"
                  variant="link"
                  class="p-0"
                  toggle-class="px-2 py-3 text-decoration-none h-100 d-flex flex-center"
                  no-caret
                  dropleft
                >
                  <template #button-content>
                    <i class="la la-ellipsis-h icon-xl pr-0 px-3"></i>
                  </template>
                  <b-dropdown-header id="dropdown-header-1" v-translate>Groups</b-dropdown-header>
                  <b-dropdown-item v-translate>Duplicate</b-dropdown-item>
                  <b-dropdown-item v-translate>Change status</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-header id="dropdown-header-1" v-translate>Groups</b-dropdown-header>
                  <b-dropdown-item v-translate>Export</b-dropdown-item>
                  <b-dropdown-item v-translate>Print</b-dropdown-item>
                </b-dropdown>-->
              </b-button-group>
            </div>
            <div>Content</div>
          </template>
        </b-table>
        <!--end::Table-->
      </div>
      <!--end::Body-->

      <!--end::Footer-->
      <div class="card-footer border-0 p-5 bg-white pl-5 pr-5">
        <b-container fluid class="p-0">
          <!-- User Interface controls -->
          <b-row class="align-items-center">
            <b-col
              class="d-none --d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75"
              v-if="filteredOperationsLength !== totalOperationsLength"
            >
              <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Filtered</label>
              <span class="font-weight-bolder">{{ filteredOperationsLength }} <translate>operations</translate></span>
            </b-col>

            <b-col
              class="d-none --d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75"
            >
              <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Total</label>
              <span class="font-weight-bolder">{{ totalOperationsLength }} <translate>operations</translate></span>
            </b-col>

            <b-col
              v-show="false"
              v-if="this.showPagination == true || this.showPagination == null"
              class="d-none --d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75"
            >
              <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Showing</label>
              <b-form inline>
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" :value="perPage" size="sm" class="w-25"></b-form-select>
                <label class="ml-sm-1" for="per-page-select" v-translate>operations per page</label>
              </b-form>
            </b-col>

            <b-col class="d-flex flex-column justify-content-center --align-items-center border-right-0 border-bottom-0 border-opalean-gray-light text-dark-75">
              <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Page</label>
              <b-pagination
                v-if="filteredOperationsLength > 0"
                v-model="currentPage"
                :total-rows="filteredOperationsLength"
                :per-page="perPage"
                aria-controls="list-table"
                first-number
                last-number
                class="my-0"
                size="sm"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!--end::Footer-->
    </div>
    <div v-else class="position-absolute top-50 start-0 w-100">
      <span class="d-flex align-items-center w-50 m-auto"
        ><span class="svg-icon svg-icon-3x mr-4 svg-icon-opalean-gray-medium">
          <inline-svg src="media/svg/icons/Code/Stop.svg" />
        </span>
        <span class="text-opalean-gray-medium"
          ><strong><translate>There is no operations</translate></strong> <translate>to validate from now...</translate></span
        >
      </span>
    </div>

    <!--end::ListOperations -->
  </div>
</template>

<script>
import Vue from "vue";
var vm = new Vue();

import { mapGetters, mapActions } from "vuex";
import DetailsPanel from "@/view/content/neoopatrace/components/DetailsPanel.vue";

import statics from "@/core/statics/statics.js";
import helpers from "@/core/statics/helpers.js";

import ApiService from "@/core/services/api.service";
//import Swal from "sweetalert2";

export default {
  name: "operations-list-component",
  components: { DetailsPanel },
  props: ["itemsLimit", "itemsPerPage", "itemsOrder", "showSearch", "showFilters", "showCreateButton", "showPagination", "showHeader"],
  data() {
    return {
      // Preferences
      showDataDebug: statics.showDataDebug,
      //
      perPage: 20,
      currentPage: 0,
      isBusy: true,
      isBulkVisible: false,
      pageOptions: [5, 10, 20, 30, 50, 100, 1000, 2000, { value: 10000, text: vm.$gettext("Show a maximum") }],
      filter: "",
      filterOn: [],
      filteredOperationsLength: 0, // b-pagination fix ! https://github.com/bootstrap-vue/bootstrap-vue/issues/1629
      fields: [
        //
        {
          key: "operationRow",
          label: vm.$gettext("Operation"),
          tdClass: "text-left position-relative pl-3 w-70px",
          stickyColumn: false, // Warning : add a background-image and break all hover / striper / selected styles
        },
        {
          key: "flowRow",
          label: vm.$gettext("Flow"),
          tdClass: "text-left w-100 pr-5",
          stickyColumn: false, // Warning : add a background-image and break all hover / striper / selected styles
        },
        {
          key: "actionRow",
          label: vm.$gettext("Actions"),
          tdClass: "text-left position-relative p-0 w-60px", // Hack for borders : border-collapse ?
        },
      ],
      palletFields: [
        {
          key: "iconRow",
          label: "",
          tdClass: "text-left w-5 py-1",
        },
        {
          key: "palletRow",
          label: vm.$gettext("Pallets"),
          tdClass: "w-45 py-1",
        },
        {
          key: "accountRow1",
          label: vm.$gettext("Credits"),
          tdClass: "text-right w-10 py-1",
        },
        {
          key: "accountRow2",
          label: vm.$gettext("Debts"),
          tdClass: "text-right w-10 py-1",
        },
        {
          key: "accountRow3",
          label: vm.$gettext("Balance"),
          tdClass: "text-right w-10 py-1",
        },
        /*{
          key: "transfertRow1",
          label: vm.$gettext("Transferts"),
          tdClass: "text-right w-10"
        }*/
      ],

      Operations: [],
      selected: [],
      selectVal: {
        OperationType: undefined,
        MyRole: undefined,
        OperationStatus: undefined,
      },
      // @imported from Statics
      OperationType: statics.flowTypes, // Helpers for filter select options
      MyRole: statics.thirdPartyTypes, // Helpers for filter select options
      OperationStatus: statics.statusTypes, // Helpers for filter select options // WARNING > Don't had s
      //
      timelineTypes: statics.timelineTypes,
      palletTypes: statics.palletTypes,
      accountTypes: statics.accountTypes,
      filterLabels: statics.filterLabels,
      // Search
      searchPartner: "",
      searchFromDate: "",
      searchToDate: "",
      searchLimit: "",
      searchStatus: "",
      runCallBySearch: false,
      // Details
      showDetails: false,
      // Search filter Partners
      searchedPartners: null,
    };
  },
  beforeMount() {
    console.log("beforeMount:: FilteredOperations::", this.FilteredOperations, this.FilteredOperations.PerPage, this.itemsPerPage);
    // Current page
    if (this.FilteredOperations.CurrentPage != null) this.currentPage = Number(this.FilteredOperations.CurrentPage);
    else this.currentPage = 1;

    // Per page
    if (this.FilteredOperations.PerPage != null) this.perPage = Number(this.FilteredOperations.PerPage);
    else this.perPage = 20;

    // FILTERS fill stored fields
    // Filter
    if (this.FilteredOperations.Filter != null && this.FilteredOperations.Filter !== "") this.filter = String(this.FilteredOperations.Filter);

    // FilterOn
    if (this.FilteredOperations.FilterOn != null && this.FilteredOperations.FilterOn.length > 0) this.filterOn = this.FilteredOperations.FilterOn;

    // Selected
    if (this.FilteredOperations.Selected != null && typeof this.FilteredOperations.Selected == "object")
      this.selectVal = Object(this.FilteredOperations.Selected);
  },
  created() {
    console.log(
      "Created:: before run: Do we have params ?",
      typeof this.FilteredOperations,
      typeof this.$route.params.runCallBySearch,
      this.$route.params.Status,
      this.$route.params.limit,
      this.searchLimit,
      this.$route.params.PartnerID,
      this.$route.params.PartnerName
    );

    /* Created from a route */

    // Partner
    if (typeof this.$route.params.PartnerID !== "undefined") {
      // Used to filter by OperationStatus
      if (typeof this.$route.params.runCallBySearch === "undefined" || this.$route.params.runCallBySearch === false)
        this.filter = this.$route.params.PartnerID.toString();
      // Used to filter by PartnerID
      else
        this.searchPartner =
          this.$route.params.PartnerID != 0 && typeof this.getPartnerByPartnerID(this.$route.params.PartnerID) !== "undefined"
            ? this.getPartnerByPartnerID(this.$route.params.PartnerID)
            : {}; // Used to search by PartnerID
    }
    if (typeof this.$route.params.PartnerName !== "undefined") this.filter = this.$route.params.PartnerName; // Used to filter by PartnerName

    // Status
    if (typeof this.$route.params.Status !== "undefined") {
      // Used to filter by OperationStatus
      if (typeof this.$route.params.runCallBySearch === "undefined" || this.$route.params.runCallBySearch === false)
        this.selectVal.OperationStatus = this.$route.params.Status;
      // Used to filter by Status
      else this.searchStatus = this.$route.params.Status; // Used to search by Status
    }

    // Run by search
    if (typeof this.$route.params.runCallBySearch !== "undefined" && this.$route.params.runCallBySearch === true) {
      // Used to define if scope list by filter or search : Default filter
      this.runCallBySearch = this.$route.params.runCallBySearch;

      // Limit
      if (typeof this.$route.params.limit !== "undefined") this.searchLimit = this.$route.params.limit;
      // Used to search by limit
      else this.searchLimit = 300;
    }

    // SEARCHES fill stored fields
    // searchPartner
    console.log("Created:: Do we have stored filters ? ", this.FilteredOperations);
    if (this.FilteredOperations.searchPartner != null && typeof this.FilteredOperations.searchPartner == "object")
      this.searchPartner = Object(this.FilteredOperations.searchPartner);

    // searchFromDate
    if (this.FilteredOperations.searchFromDate != null && this.FilteredOperations.searchFromDate !== "")
      this.searchFromDate = String(this.FilteredOperations.searchFromDate);

    // searchToDate
    if (this.FilteredOperations.searchToDate != null && this.FilteredOperations.searchToDate !== "")
      this.searchToDate = String(this.FilteredOperations.searchToDate);

    // searchLimit
    if (this.FilteredOperations.searchLimit != null && this.FilteredOperations.searchLimit !== "")
      this.searchLimit = String(this.FilteredOperations.searchLimit);

    // searchStatus
    if (this.FilteredOperations.searchStatus != null && this.FilteredOperations.searchStatus !== "")
      this.searchStatus = String(this.FilteredOperations.searchStatus);

    // https://jerickson.net/created-vs-mounted-in-vue/
    // First run call ( with a status search if needed )
    // this.runCall(
    //   this.runCallBySearch === true ? 9999 : undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   this.runCallBySearch === true ? this.searchStatus : undefined
    // );
    console.log(
      "Created:: will run",
      this.$route.params.limit,
      this.searchLimit,
      this.FilteredOperations.searchLimit,
      this.searchLimit !== "" || this.searchLimit !== 0,
      this.FilteredOperations.searchLimit != null && this.FilteredOperations.searchLimit !== ""
    );
    this.runCall(
      this.searchLimit !== "" || this.searchLimit !== 0 ? this.searchLimit : undefined,
      this.searchPartner !== "" ? this.searchPartner : undefined,
      this.searchFromDate !== "" ? this.searchFromDate : undefined,
      this.searchToDate !== "" ? this.searchToDate : undefined,
      this.searchStatus !== "" || this.runCallBySearch === true ? this.searchStatus : undefined
    );
  },
  mixins: [helpers],
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      // if (item.operationHighlighted === true) return "table-success"; // Not needed anymore
      if (item.OperationStatus !== null) return "border-bottom border-2 border-bottom-white --border-bottom-opalean-gray-light status-" + item.OperationStatus;
    },
    palletRowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.OperationType === "T") return "text-info";
    },
    // Table events
    onFiltered(filteredItems) {
      console.log("onFiltered::");
      // Then store Operations list IDs and Filters to store
      this.callFilteredOperations(filteredItems);
    },
    onTableChanged(ctx) {
      console.log("onTableChanged::", ctx, ctx.currentPage, ctx.perPage);
      // Then update store
      if (ctx.currentPage !== undefined)
        this.updateFilteredOperations({
          CurrentPage: this.currentPage,
        });
      if (ctx.perPage !== undefined)
        this.updateFilteredOperations({
          PerPage: this.perPage,
        });
    },
    //
    haveAnyFilters() {
      return (this.filter == undefined || this.filter == "") && this.filterOn.length == 0 && Object.keys(this.selectVal).every((k) => !this.selectVal[k])
        ? true
        : false;
    },
    clearAllFilters(e) {
      // Prevent default
      e.preventDefault();
      // Needs to debounce because it's happening right at the same time from unblur
      setTimeout(() => {
        this.filter = "";
        this.filterOn = [];
        Object.keys(this.selectVal).forEach((key) => {
          this.selectVal[key] = undefined;
        });
      }, 100);
    },
    haveAnySearchs() {
      return this.searchPartner == "" && this.searchFromDate == "" && this.searchToDate == "" && this.searchLimit == "" && this.searchStatus == ""
        ? true
        : false;
    },
    haveAnyStoredSearchs() {
      return this.FilteredOperations.searchPartner == "" &&
        this.FilteredOperations.searchFromDate == "" &&
        this.FilteredOperations.searchToDate == "" &&
        this.FilteredOperations.searchLimit == "" &&
        this.FilteredOperations.searchStatus == ""
        ? true
        : false;
    },
    clearAllSearchs(e) {
      // Prevent default
      e.preventDefault();
      // Needs to debounce because it's happening right at the same time from unblur
      setTimeout(() => {
        this.searchPartner = "";
        this.searchFromDate = "";
        this.searchToDate = "";
        this.searchLimit = "";
        this.searchStatus = "";
        // Re-run to clear
        this.runSearchs();
      }, 100);
    },
    runSearchs() {
      console.log("runSearchs::runCall + callFilteredOperations");
      // Run call
      this.runCall(this.searchLimit, this.searchPartner, this.searchFromDate, this.searchToDate, this.searchStatus);
      // Watch and store Operations list Searches to store
      this.callFilteredOperations();
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },

    // Static helpers methods
    // Used when v-for and v-if needed : v-if="item.MainRole == role.id"
    // ** See helpers.js
    // Local helpers
    getPalletValue(id, value) {
      console.log("getPalletValue::", id, value);
      let _Pallet = [];
      if (typeof id !== "undefined" && typeof value !== "undefined") _Pallet = this.getPallet(id);
      if (typeof _Pallet !== "undefined") return _Pallet[value];
      else return [];
    },
    getOptionNamebyKeys(key, option, label = "name") {
      console.log("getOptionNamebyKeys::", key, option, label);
      // let _key = key + "s";
      //return this[_key][option - 1][label]; // Old v1
      // console.log("getOptionNamebyKeys::label", this[key].filter(f => f.id == option)[label]); // Returns undefined
      // console.log("getOptionNamebyKeys::label", this[key].filter(f => f.id == option)[0][label]); // Returns String
      // console.log("getOptionNamebyKeys::label",this[key].filter(f => f.id == option).map(f => f[label])); // Returns Array
      try {
        return this[key].filter((f) => f.id == option)[0][label];
      } catch (e) {
        // Log
        console.log("[ERROR]getOptionNamebyKeys::" + e, key, option, label);

        // Then, message
        window.swalAlert.fire({
          title: vm.$gettext("Filters error"),
          html: vm.$gettext("Please contact our support."),
          // timer: 1200,
          // timerProgressBar: true,
          confirmButtonClass: "btn btn-outline-secondary",
          // heightAuto: false,
          icon: "error",
          footer: `<code>GetOptionNamebyKeys | ${key} | ${option}</code>`,
        });

        // Then, unknown option name
        return "-";
      }
    },

    //API Calls
    runCall(limit, partner, fromdate, todate, status) {
      console.log("runCall::", limit, partner, fromdate, todate, status);
      let params = "";
      params +=
        limit !== undefined && limit !== ""
          ? "limit=" + limit
          : "limit=" + (this.itemsLimit == 0 || this.itemsLimit == null ? this.getUserGetOperationsLimit : this.itemsLimit);
      params += partner !== undefined && partner !== "" ? "&partner=" + partner.PartnerID : "";
      params += fromdate !== undefined && fromdate !== "" ? "&fromdate=" + fromdate : "";
      params += todate !== undefined && todate !== "" ? "&todate=" + todate : "";
      params += status !== undefined && status !== "" ? "&status=" + status : "";
      console.log("runCall:: params:: ", params);
      // Call API
      ApiService.get("/operations", params).then((response) => {
        console.log("/operations::this.itemsLimit", this.itemsLimit);
        console.log("/operations::response", response);
        // Handle orders
        if (this.itemsLimit == 0 || this.itemsLimit == null) this.Operations = response.data.Operations;
        else {
          if (response.data.Operations.length < this.itemsLimit) this.Operations = response.data.Operations;
          else this.Operations = response.data.Operations.slice(0, this.itemsLimit);
          if (this.itemsOrder == "DESC") this.Operations = this.Operations.reverse();
        }
        // Add data & labels for filters ( NDW : see it's not to busy to load )
        this.Operations.forEach((o) => {
          // Partners
          o._PartnerIDs = [];
          o._PartnerNames = [];
          o.Partners.forEach((p) => {
            if (p.PartnerID !== undefined) {
              if (p.PartnerID == 0 && p.PartnerFullName != "") o._PartnerNames.push(p.PartnerFullName);
              else if (p.PartnerID != 0 && typeof this.getPartnerByPartnerID(p.PartnerID) !== "undefined") {
                o._PartnerIDs.push(p.PartnerID);
                o._PartnerNames.push(this.getPartnerByPartnerID(p.PartnerID).Name, _.lowerCase(_.deburr(this.getPartnerByPartnerID(p.PartnerID).Name))); // Deburrs string by converting Latin-1 Supplement and Latin Extended-A letters to basic Latin letters and removing combining diacritical marks.
              }
            }
          });
          // Pallets
          o._PalletIDs = [];
          o._PalletNames = [];
          o.POVs.forEach((pov) => {
            pov.Pallets.forEach((pallet) => {
              if (pallet.PalletID !== undefined) {
                if (pallet.PalletID !== 0 && typeof this.getPallet(pallet.PalletID) !== "undefined") {
                  o._PalletIDs.push(pallet.PalletID);
                  o._PalletNames.push(this.getPallet(pallet.PalletID).PalletName);
                }
              }
            });
          });
          // Date
          o._OperationDate = new Date(o.OperationDate).toLocaleDateString();
        });
        // Toggle busy state
        this.isBusy = false;

        // Update length
        this.filteredOperationsLength = this.Operations.length;
        // From props
        console.log("runCall:: itemsPerPage::", this.itemsPerPage, this.FilteredOperations.PerPage);
        if (this.itemsPerPage !== null && this.FilteredOperations.PerPage == null) this.perPage = this.itemsPerPage;
        // From store
        console.log(
          "runCall:: this.FilteredOperations::",
          this.FilteredOperations,
          this.FilteredOperations.CurrentPage,
          this.FilteredOperations.PerPage,
          this.FilteredOperations.CurrentPage != null,
          this.FilteredOperations.PerPage != null
        );
        // if (this.FilteredOperations.PerPage != null) this.perPage = Number(this.FilteredOperations.PerPage);
        // if (this.FilteredOperations.CurrentPage != null) this.currentPage = Number(this.FilteredOperations.CurrentPage);
        // else this.currentPage = 1;
      });
    },
    validateOperation(OperationUID) {
      console.log("validateOperation::", OperationUID);

      // Const
      this.isValidating = true;
      let actionTitle = vm.$gettext("Validate");
      let actionMessage = vm.$gettext("validated");

      // Validate Operation
      (async () => {
        let response = null;
        let errorStatus = null;
        let errorData = null;
        try {
          response = await ApiService.get("/AgreePartnerPOV" + (typeof OperationUID !== "undefined" ? "?ID=" + OperationUID : ""));
        } catch (error) {
          console.error("validateOperation:: Error response:");
          console.error(error.response.data); // ***
          console.error(error.response.status); // ***
          console.error(error.response.headers); // ***

          errorStatus = error.response.status;
          errorData = error.response.data.errors.join(", ");
        } finally {
          console.log(response);
          console.log(errorStatus);
          this.isValidating = false;

          if (response === null && errorStatus !== null) {
            // Error
            window.swalAlert.fire({
              title: errorStatus,
              html: vm.$gettext("Damned, we are sorry, we have an error...") + (errorData !== null ? "<br/><code> " + errorData + " </code>" : ""),
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              footer: '<a href="mailto:support@opalean.fr">' + vm.$gettext("Need to contact support ?") + "</a>",
            });
          } else if (response !== null && response.status === 200) {
            console.info("validateOperation:: Success response:", response);

            // Success
            window.swalToast.fire({
              title: actionTitle + " " + vm.$gettext("Operation"),
              html: vm.$gettext("The operation has been successfully") + " <em>" + actionMessage + "</em> !",
              icon: "success",
              timer: 2000,
              confirmButtonClass: "btn btn-secondary",
              footer: vm.$gettext("Operation list will be automatically reloaded in few seconds..."),
            });

            // Reload if OperationID
            setTimeout(() => {
              console.log("validateOperation:: Success, then reload...");
              //this.runCall(); // Fix loosing search filters
              this.runCall(
                this.runCallBySearch === true ? 9999 : undefined, //9999
                undefined,
                undefined,
                undefined,
                this.runCallBySearch === true ? this.searchStatus : undefined
              );
            }, 2000);
          }
        }
      })();
    },

    // Update filtered Operations to store IDs and filter when editing
    ...mapActions(["updateFilteredOperations"]),
    callFilteredOperations(filteredItems) {
      if (filteredItems === undefined) filteredItems = this.filteredItems;
      console.log("updateFilteredOperations::", this.filteredItems.length, filteredItems);

      // Store updated items length & trigger pagination to update the number of buttons/pages due to filtering
      this.filteredOperationsLength = filteredItems.length;
      // this.currentPage = 1;

      // Then update store ( only if loaded )
      if (this.filteredItems.length > 0)
        this.updateFilteredOperations({
          //IDs: filteredItems.map((o) => o.OperationID), // V4
          IDs: filteredItems.map((o) => o.OperationUID), // V6
          /* IDs: filteredItems.map((o) => {
            return { OperationID: o.OperationID, OperationType: o.OperationType, isOS: o.isOS };
          }), // V6 */
          maxDate: new Date(Math.max(...filteredItems.filter((o) => o.OperationDate !== null).map((o) => new Date(o.OperationDate)))),
          minDate: new Date(Math.min(...filteredItems.filter((o) => o.OperationDate !== null).map((o) => new Date(o.OperationDate)))),
          Filter: this.filter,
          FilterOn: this.filterOn,
          Selected: this.selectVal,
          //CurrentPage: this.currentPage, // Do not update here, because function is called before runCall
          //PerPage: this.perPage, // Do not update here, because function is called before runCall

          // Searchs
          searchPartner: this.searchPartner,
          searchFromDate: this.searchFromDate,
          searchToDate: this.searchToDate,
          searchLimit: this.searchLimit,
          searchStatus: this.searchStatus,
        });
    },

    // Get date
    getNow() {
      return moment().format("DDMMYYYY_h-mm");
    },

    // Fix Multiselect search without Diacritics ( accents )
    /* inspired by https://github.com/shentao/vue-multiselect/issues/281#issuecomment-597718858 */
    /* inside map, this refer to Window and not App */
    /* https://stackoverflow.com/questions/46371302/map-unable-to-access-objects-this-function */
    ignoreAccents(search) {
      const needle = _.lowerCase(_.deburr(search));

      console.log(
        "ignoreAccents::",
        search,
        search.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
        needle,
        this.Partners.filter(
          (p) =>
            _.lowerCase(_.deburr(p.Name)).includes(needle) ||
            _.lowerCase(_.deburr(p.City)).includes(needle) ||
            _.lowerCase(_.deburr(p.ZipCode)).includes(needle) ||
            _.lowerCase(_.deburr(p.Reference)).includes(needle) ||
            (typeof p.PartnerRef != "undefined" ? _.lowerCase(_.deburr(p.PartnerRef)).includes(needle) : "")
        )
      );

      this.searchedPartners = search
        ? this.Partners.filter(
            (p) =>
              _.lowerCase(_.deburr(p.Name)).includes(needle) ||
              _.lowerCase(_.deburr(p.City)).includes(needle) ||
              _.lowerCase(_.deburr(p.ZipCode)).includes(needle) ||
              _.lowerCase(_.deburr(p.Reference)).includes(needle) ||
              (typeof p.PartnerRef != "undefined" ? _.lowerCase(_.deburr(p.PartnerRef)).includes(needle) : "")
          )
        : this.Partners;
    },
  },
  watch: {
    // Edit
    // "$route.params.Status": {
    //   handler: function (Status) {
    //     console.log("[LIST] watch router:: Status > runSearchs > runCall", Status, this.searchStatus);
    //     this.searchStatus = Status; // Used to search by Status
    //     this.runSearchs();
    //   },
    //   //deep: true,
    //   immediate: false,
    // },

    // "$route.params.limit": {
    //   handler: function (limit) {
    //     console.log("[LIST] watch router:: limit > runSearchs > runCall", limit, this.searchLimit);
    //     this.searchLimit = limit; // Used to search by Status
    //     this.runSearchs();
    //   },
    //   //deep: true,
    //   immediate: false,
    // },

    "$route.params": {
      handler: function (params) {
        console.log("[LIST] watch router:: params > runSearchs > runCall", params);

        // Run by search
        if (typeof this.$route.params.runCallBySearch !== "undefined" && this.$route.params.runCallBySearch === true)
          this.runCallBySearch = this.$route.params.runCallBySearch;
        else this.runCallBySearch = false;

        // Partner
        if (typeof this.$route.params.PartnerID !== "undefined") {
          this.searchPartner =
            this.$route.params.PartnerID != 0 && typeof this.getPartnerByPartnerID(this.$route.params.PartnerID) !== "undefined"
              ? this.getPartnerByPartnerID(this.$route.params.PartnerID)
              : {}; // Used to search by PartnerID
        } else {
          this.searchPartner = undefined;
        }

        // Status
        if (typeof this.$route.params.Status !== "undefined") this.searchStatus = this.$route.params.Status;
        else this.searchStatus = undefined;

        // Limit
        if (typeof this.$route.params.limit !== "undefined") this.searchLimit = this.$route.params.limit;
        else this.searchLimit = 300;

        // Then, run seach
        this.runSearchs();
      },
      //deep: true,
      immediate: false,
    },

    filteredItems: {
      //deep: true, // Detect nested value changes inside Objects
      immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function (newItems) {
        console.log("Watch::filteredItems length=" + newItems.length);
        // Watch and store Operations list IDs and Filters to store
        this.callFilteredOperations();
      },
    },

    currentPage: {
      //deep: true, // Detect nested value changes inside Objects
      immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function (o, n) {
        console.log("Watch::currentPage", o, n, this.FilteredOperations.CurrentPage);
      },
    },
  },
  computed: {
    ...mapGetters(["getPartnersFor", "getPartnerByPartnerID", "getPallets", "getPallet", "getUserGetOperationsLimit", "getFilteredOperations"]),
    // Get Partners data
    Partners: function () {
      return this.getPartnersFor("STATS");
    },
    Pallets: function () {
      return this.getPallets;
    },
    // Get how operations in list was filtered
    FilteredOperations() {
      return this.getFilteredOperations;
    },
    // Items
    totalOperationsLength() {
      return this.Operations.length;
    },
    // Todo sorting > will probably need a custom sorting function
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    // Filtering
    // @TODO using : Provider https://bootstrap-vue.org/docs/components/table#using-items-provider-functions
    filteredItems() {
      // filteredItems(ctx, callback) {
      //  console.log("filteredItems:: currentPage", ctx.currentPage);
      return this.Operations.filter((item) => {
        let keep = true;
        // This is a basic equality filter. What I did in the actual code was to have an object with filter functions for each key. If a key was missing, it defaulted to straight equality.
        this.fieldKeys.forEach((key) => {
          keep = keep && (this.selectVal[key] === undefined || item[key] === this.selectVal[key]);
        });
        return keep;
      });
    },
    fieldKeys() {
      return Object.keys(this.selectVal);
      //return Object.keys(this.Operations[0]);
    },
    listOptions() {
      // This could be simplified if the select was it's own component.
      const listOptions = {};
      this.fieldKeys.forEach((key) => {
        const vals = this.Operations.map((item) => item[key]);

        // Cheap and efficient unique.
        listOptions[key] = Array.from(new Set(vals));
      });

      return listOptions;
    },
    // DEV > Show stored datas
    StoredDatas() {
      return this.$store.state;
    },
  },
};
</script>
